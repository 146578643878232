import React, { useState, useContext } from "react";
import { db } from "./firebase";

export const DataContext = React.createContext({});
export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [fetched, setFetched] = useState(false);
  const [points, setPoints] = useState(0);
  function testUrl(url) {
    try {
      atob(url);
      return true;
    } catch (error) {
      return false;
    }
  }
  const fetchData = (quizUrl, type, short) => {
    if (type === "long") {
      if (!testUrl(quizUrl)) {
        alert("There is an error with this url.");
        return;
      }
      const decoded = decodeURI(atob(quizUrl));
      const [user, quiz] = decoded.split("-");
      if (!user || !quiz) {
        alert("There is an error with this url.");
        return;
      }
      db.collection("Users")
        .doc(user)
        .collection("Quizzes")
        .doc(quiz)
        .get()
        .then(doc => {
          if (doc) {
            setData(doc.data());
            setFetched(true);
          }
        });
    }
    if (type === "short") {
      db.collection("Links")
        .doc(short)
        .get()
        .then(doc => {
          console.log(doc.data());
          if (doc.data()) {
            window.location.href = doc.data().url;
          }
        });
    }
  };

  const updatePoints = () => {
    setPoints(oldPoints => {
      const newPoints = oldPoints + 1;
      localStorage.setItem("correct", newPoints);
      return newPoints;
    });
  };

  const resetPoints = () => {
    localStorage.removeItem("correct");
    setPoints(0);
  };

  const exports = {
    intro: data.start,
    questions: data.questions,
    outro: data.finish,
    impressum: data.impressum,
    fetched,
    fetchData,
    points,
    updatePoints,
    resetPoints,
    name: data.name,
    fontScheme: data.fontScheme,
    mainColor: data.mainColor
  };

  return (
    <DataContext.Provider value={exports}>{children}</DataContext.Provider>
  );
};
