import React from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function SolutionView({
  data,
  goToNextQuestion,
  isCorrect,
  Counter
}) {
  const { t } = useTranslation();

  const { id } = useParams();
  return (
    <div id="SolutionView" className="container">
      <motion.div
        key={id}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{ backgroundImage: "url(" + data.image + ")" }}
        className="image"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      ></motion.div>
      <div className="content">
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ y: 100, opacity: 0, transition: { duration: 0.5 } }}
          transition={{ duration: 0.5 }}
        >
          <Counter />
          <h1>{isCorrect ? t("Correct!") : t("Wrong ...")}</h1>
          <div>{data.explanation}</div>
          <div className="btn" onClick={e => goToNextQuestion()}>
            {t("Continue")}
          </div>
        </motion.div>
      </div>
    </div>
  );
}
