import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Impressum() {
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container"
    >
      <div className="inner">
        <h1>Info</h1>
        <div>
          {t("This quiz has been created with")}{" "}
          <a href="https://wonderquiz.netlify.app">wonderquiz</a>
        </div>
      </div>
    </motion.div>
  );
}
