import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import initReactFastclick from 'react-fastclick';
import { DataProvider } from './util/dataProvider';
import { HelmetProvider } from 'react-helmet-async';

initReactFastclick();

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<DataProvider>
				<HelmetProvider>
					<App />
				</HelmetProvider>
			</DataProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
