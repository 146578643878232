import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useData } from "../../util/dataProvider";
import { motion } from "framer-motion";
import QuestionView from "./QuestionView";
import SolutionView from "./SolutionView";
import { useTranslation } from "react-i18next";

export default function Question() {
  const { t } = useTranslation();

  const { questions, updatePoints } = useData();
  const history = useHistory();
  const [answered, setAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [error, setError] = useState("");

  const { id, quiz } = useParams();
  useEffect(() => {
    setAnswered(false);
  }, [id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id, answered]);
  const currentQuestion = questions[id - 1];

  const Counter = () => {
    return (
      <motion.div
        transition={{ delay: 0.5 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {id}/{questions.length}
      </motion.div>
    );
  };

  function answerQuestion(answer) {
    if (!answer) {
      const string = t("Please select an answer.");
      setError(string);
    } else {
      setError("");
      if (currentQuestion.correct_answer === answer) {
        setIsCorrect(true);
        updatePoints();
      }
      setAnswered(true);
    }
  }
  function goToNextQuestion() {
    const nextQuestion = parseInt(id) + 1;
    if (nextQuestion > questions.length) {
      history.push(`/${quiz}/finish`);
    } else {
      history.push(`/${quiz}/question/${nextQuestion}`);
    }
  }
  if (!currentQuestion) {
    return <div className="container">Question not found.</div>;
  }
  if (answered)
    return (
      <SolutionView
        isCorrect={isCorrect}
        data={currentQuestion}
        Counter={Counter}
        goToNextQuestion={goToNextQuestion}
      />
    );
  return (
    <QuestionView
      Counter={Counter}
      error={error}
      data={currentQuestion}
      answerQuestion={answerQuestion}
    />
  );
}
