import React from "react";
import "./assets/styles/App.scss";
import { Switch, Route, useLocation } from "react-router-dom";
import { useData } from "./util/dataProvider";
import { Loader } from "./components/common";
import { Layout, Intro, Outro, Question, Impressum, Theme } from "./components";
import { AnimatePresence } from "framer-motion";
import "./i18n";
import { Helmet } from "react-helmet-async";

function App() {
  const { fetched, name, intro } = useData();
  let location = useLocation();

  if (!fetched) {
    return (
      <Switch>
        <Route
          path="/q/:short"
          render={props => <Loader {...props} type="short" />}
        />
        <Route
          path="/:quiz"
          render={props => <Loader {...props} type="long" />}
        />
      </Switch>
    );
  }
  return (
    <div className="App">
      <Helmet link={[{ rel: "icon", type: "image/png", href: intro.logo }]}>
        <title>{name}</title>
      </Helmet>
      <Route path="/:quiz">
        <Layout>
          <Theme />
          <AnimatePresence>
            <Switch location={location} key={location.pathname}>
              <Route path="/:quiz/question/:id" component={Question} />
              <Route path="/:quiz/finish" component={Outro} />
              <Route path="/:quiz/contact" component={Impressum} />
              <Route path="/:quiz" component={Intro} />
            </Switch>
          </AnimatePresence>
        </Layout>
      </Route>
    </div>
  );
}

export default App;
