import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { fr, de, es } from "./languages";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  de: {
    translation: de
  },
  fr: {
    translation: fr
  },
  es: {
    translation: es
  }
};

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng"
};

i18next
  .use(LanguageDetector)

  .use(initReactI18next) // passes i18n down to react-i18next

  .init({
    detection: options,

    resources,
    whitelist: [
      "es",
      "en",
      "da",
      "de",
      "el",
      "fi",
      "fil",
      "tl",
      "fr",
      "hi",
      "id",
      "it",
      "ja"
    ],
    debug: false,
    fallbackLng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18next;
