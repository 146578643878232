import React from "react";
import { useData } from "../../util/dataProvider";
import { useHistory, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Outro() {
  const { t } = useTranslation();

  const { outro, questions } = useData();
  const { quiz } = useParams();
  const history = useHistory();
  const search1 = /%correct%/gi;
  const search2 = /%possible%/gi;
  let newText = outro.text.replace(search1, localStorage.getItem("correct"));
  newText = newText.replace(search2, questions.length);
  return (
    <div className="container" id="Outro">
      <div className="inner">
        <motion.h1
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {outro.title}
        </motion.h1>
        <motion.div
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: -100 }}
          exit={{ opacity: 0 }}
          className="text"
        >
          {newText}
        </motion.div>
        <motion.div
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: -100 }}
          exit={{ opacity: 0 }}
          className="text"
        >
          {t("You got")}{" "}
          {localStorage.getItem("correct")
            ? localStorage.getItem("correct")
            : 0}{" "}
          {t("out of")} {questions.length} {t("answers right")}.
        </motion.div>
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.5 }}
          className="btn"
          onClick={() => history.push(`/${quiz}`)}
        >
          {t("Restart quiz")}
        </motion.div>
      </div>
    </div>
  );
}
