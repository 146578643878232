import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';

var firebaseConfig = {
	apiKey: 'AIzaSyDsX1A7RxLxOCbZGoMhGGBlWk3BXCyXUFQ',
	authDomain: 'wonderquiz-6ca2a.firebaseapp.com',
	databaseURL: 'https://wonderquiz-6ca2a.firebaseio.com',
	projectId: 'wonderquiz-6ca2a',
	storageBucket: 'wonderquiz-6ca2a.appspot.com',
	messagingSenderId: '504456262673',
	appId: '1:504456262673:web:8092f31c78763ccafe6db0',
	measurementId: 'G-3G8ENPYMCC',
};
firebase.initializeApp(firebaseConfig);

export const analytics = firebase.analytics();
export const db = firebase.firestore();
