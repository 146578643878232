import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Menu from "../../assets/images/menu";
import close from "../../assets/images/cancel.svg";
import { useData } from "../../util/dataProvider";

export default function Layout({ children }) {
  const { intro, mainColor } = useData();
  const { quiz } = useParams();
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <div id="Layout">
      <div id="Header">
        {intro.logo && <img src={intro.logo} alt="Logo" className="logo" />}
        <Menu color={mainColor} onClick={() => setMenuVisible(true)} />
      </div>
      <AnimatePresence>
        {menuVisible && (
          <motion.div
            transition={{ ease: "easeInOut" }}
            animate={{ x: 0 }}
            initial={{ x: "100%" }}
            exit={{ x: "100%" }}
            className="menu"
          >
            <img
              src={close}
              alt="Close"
              className="close"
              onClick={() => setMenuVisible(false)}
            />
            <ul>
              <li>
                <NavLink onClick={() => setMenuVisible(false)} to={`/${quiz}`}>
                  Start
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setMenuVisible(false)}
                  to={`/${quiz}/contact`}
                >
                  Info
                </NavLink>
              </li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </div>
  );
}
