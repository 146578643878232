import React from "react";
import { useData } from "../../util/dataProvider";
import Color from "./color";

export default function Theme() {
  const { fontScheme, mainColor } = useData();
  const FS1 = React.lazy(() => import("./fs_1"));
  const FS2 = React.lazy(() => import("./fs_2"));
  const FS3 = React.lazy(() => import("./fs_3"));

  return (
    <React.Fragment>
      <React.Suspense fallback={""}>
        <Color color={mainColor} />
        {fontScheme === "fs_01" ? <FS1 /> : ""}
        {fontScheme === "fs_02" ? <FS2 /> : ""}
        {fontScheme === "fs_03" ? <FS3 /> : ""}
      </React.Suspense>
    </React.Fragment>
  );
}
