import React from "react";

export default function Color({ color }) {
  const style = `
        h1,
        h2,
        h3 {
            color: ${color};
        }
        h1:after {
            background: ${color};
        }
        .btn {
            color: ${color};
        	border-bottom: 1px solid ${color};
        }

        #Layout .menu {
            background: ${color};
        }
        
        .radio {
    		border: 1px solid ${color};
        }
        #SolutionView .image {
            background-color: ${color};
        }
  `;

  return <style dangerouslySetInnerHTML={{ __html: style }}></style>;
}
