import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useData } from "../../util/dataProvider";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Intro() {
  const { t } = useTranslation();

  const { intro, resetPoints } = useData();
  const history = useHistory();
  const { quiz } = useParams();
  return (
    <div className="container" id="Intro">
      <div className="inner">
        <motion.img
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1, transition: { delay: 1 } }}
          exit={{ opacity: 0, transition: { delay: 0 } }}
          src={intro.logo}
        ></motion.img>
        <motion.h1
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0, transition: { delay: 0 } }}
        >
          {intro.title}
        </motion.h1>
        <motion.div
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: -100 }}
          exit={{ opacity: 0 }}
          className="text"
        >
          {intro.text}
        </motion.div>
        <motion.div
          exit={{ opacity: 0, transition: { delay: 0 } }}
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="btn"
          onClick={() => {
            resetPoints();
            history.push(`/${quiz}/question/1`);
          }}
        >
          {t("Start quiz")}
        </motion.div>
        <style
          dangerouslySetInnerHTML={{ __html: ".logo {opacity:0;}" }}
        ></style>
      </div>
    </div>
  );
}
